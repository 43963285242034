<template lang="pug">
  Main#cases
    section
      .container
        .row.center-lg.center-xs.middle-xs
          .col-lg-6.col-md-10.col-sm-8.col-xs-10
            h1 {{ $t("cases.title") }}
          .col-lg-6.col-md-10.col-sm-8.col-xs-10
            #astronauts.box-row
              img(class="astronauta-vr floating", src="../../assets/images/astronauta-vr.png")
              img(class="meteorito-2 float-rotate", src="../../assets/images/meteorito02.svg")
              img(class="meteorito-3 float-rotate", src="../../assets/images/meteorito03.svg")
              img(class="meteorito-4 float-rotate", src="../../assets/images/meteorito04.svg")
    section#our-cases
      .container
        .row.center-lg.center-xs.middle-xs
          .col-lg-10.col-md-10.col-sm-10.col-xs-10       
            h2  {{ $t("cases.our_cases.title") }}
    section
      ContactSection
</template>

<script>
import Main from "../Template/Main";
import ContactSection from "../../components/ContactSection/ContactSection";

export default {
  name: "Cases",
  components: {
    Main,
    ContactSection
  },
  data() {
    return {
      isMobile: false
    };
  },
  methods: {
    mobileBreakpointCheck() {
      let vm = this;
      let breakPoint = 1023;
      if (window.innerWidth > breakPoint) {
        vm.isMobile = false;
      } else {
        vm.isMobile = true;
      }
    }
  },
  created() {
    let vm = this;
    $(window).resize(function() {
      vm.mobileBreakpointCheck();
    });
  },
  mounted() {
    let vm = this;
    vm.mobileBreakpointCheck();
    vm.$bus.$emit("load", false);
  }
};
</script>

<style lang="scss">
main {
  background-color: #000;
  &.our-cases {
    background-color: #07061b;
  }
}
</style>